/*


  Objetivo de esta hoja de estilos
  --------------------------------------------------------------
   Incluye solamente los estilos particulares del frontend o
   parte pública del sitio web. Esto permite aplicar estilos
   particulares al backend y a la extranet.

  Se aplica a
  --------------------------------------------------------------
   extranet

*/

/* ===========================================================================
   Estructura
   ======================================================================== */
article {
    float: left;
    padding: 0 20px 0 20px;
    /*width: 700px;*/
    width: 100%;
}
article > section {
    margin-bottom: 1em;
}

section:before, section:after { content: ""; display: table; }
section:after { clear: both; }
section { zoom: 1; }

aside {
    float: right;
    padding: 0 20px 0 10px;
    /*width: 200px;*/
    width: 25%;
}

/* ===========================================================================
   Cabecera y menú de navegación
   ======================================================================== */
nav ul {
    left: 180px;
}
nav ul li:last-child {
   float: right;
   padding-right: .5em
}
nav ul li select#ciudadseleccionada {
   max-width: 200px;
}

#portada   nav li:nth-child(1) a,
#recientes nav li:nth-child(2) a,
#compras   nav li:nth-child(3) a {
    border: none;
    color: #FF3385 !important;
    font-size: 21px;
    font-weight: normal;
    text-decoration: none;
}

/* ===========================================================================
   Zona lateral
   ======================================================================== */
aside #login h2 {
    color: #777;
    margin: -.5em 0 .5em;
    text-align: center;
}
aside #login .boton {
    background-color: #008000;
    color: #FFF;
    display: block;
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 1em;
    padding: .5em 1em;
    text-align: center;
    text-shadow: none;
}
aside #login .boton:hover { background: rgba(0,128,0, 0.8); }
aside:hover #login .boton { text-decoration: none; }

aside #login form {
    background: #DCDCDC;
    padding: .5em;
}
aside #login label {
    display: block;
    font-size: 14px;
    font-weight: bold;
}
aside #login input[type="checkbox"] + label {
    display: inline;
}
aside #login input {
    font-size: 14px;
    padding: 2px;
    margin-bottom: 7px;
}
aside #login form input[type="submit"] {
    margin: 0;
    padding: 4px 7px;
    position: static;
}
aside #login input[name="_username"] {
    width: 170px;
}
aside #login input[name="_password"] {
    width: 110px;
}

/* Cuando el usuario ya se ha conectado... */
aside #login p strong {
    display: block;
    font-size: 18px;
}
aside #login a, aside #safata a {
    display: block;
    font-size: 16px;
    margin: .5em;
}

aside #relacionadas ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
aside #relacionadas li {
    font-weight: bold;
}
aside #relacionadas li a {
    font-weight: normal;
}

aside #condiciones, aside #nosotros {
    color: #444;
}

/* ===========================================================================
   Ficha de Oferta
   ======================================================================== */
.oferta h1 {
    margin-top: 0;
}

.oferta .descripcion {
    float: left;
    font-size: 14px;
    width: 350px;
}

.oferta .descripcion h1 a,
.oferta.mini .descripcion h2 a {
    color: #C06;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    text-decoration: none;
}
.oferta.mini .descripcion h2 a {
    font-size: 21px;
}
.oferta .descripcion h1 a:hover,
.oferta.mini .descripcion h2 a:hover {
    color: #2200C1 !important;
}

.oferta .boton {
    font-size: 28px;
    padding: .4em .75em;
}

.oferta .galeria {
    font-size: 13px;
    float: right;
    position: relative;
    width: 320px;
}
.oferta .galeria img {
    width: 320px;
    margin-bottom: 13px;
}
.oferta .galeria .precio {
    background: rgba(0, 21, 78, 0.9);
    color: #FFF;
    display: inline-block;
    font-size: 28px;
    padding: .25em .5em;
    position: absolute;
    top: 192px;
    left: 0;
}
.oferta .galeria .precio span {
    color: #FF3385;
    font-size: 21px;
    font-weight: bold;
    padding-left: .5em;
    text-shadow: 0 1px 1px #00154E;
}

.oferta .estado {
    background: #DCDCDC;
    clear: both;
    margin: 1em 0;
    padding: .5em 1em;
    overflow: hidden;
}
.oferta .estado div {
    float: left;
}
.oferta .estado .tiempo, .oferta .estado .compras {
    font-size: 21px;
    line-height: 36px;
    width: 35%;
}
.oferta .estado .faltan {
    width: 30%;
}
.oferta .expirada .tiempo {
    width: 60%;
}
.oferta .expirada .compras {
    width: 35%;
}

.oferta .direccion {
    float: left;
    width: 200px;
}
.oferta .tienda {
    float: right;
    width: 450px;
}
.oferta .direccion h2, .oferta .tienda h2 {
    font-size: 16px;
    margin-bottom: .25em;
}

.oferta .direccion a {
    display: block;
    font-size: 18px;
    padding-bottom: .25em;
}
.oferta .direccion p {
    font-size: 14px;
}

.oferta .tienda {
    font-size: 13px;
    line-height: 1.4;
}

/* ===========================================================================
   Mini-ficha de Oferta
   ======================================================================== */
.oferta.mini {
   margin: 1em 0 2em;
}

.oferta.mini h2 {
   margin-top: 0;
}

.oferta.mini .descripcion {
   float: left;
   width: 400px;
   font-size: 14px;
}
.oferta.mini .descripcion p {
   margin-top: 1em;
}

.oferta.mini .boton {
    font-size: 21px;
}

.oferta.mini .estado {
    background: none;
    padding: 0 1em;
}
.oferta.mini .estado.expirada,
.oferta.mini .estado.comprada {
    font-weight: bold;
}
.oferta.mini .boton + .estado {
    float: right;
}

.oferta.mini .galeria {
   float: right;
   width: 240px;
}
.oferta.mini .galeria img {
   width: 240px;
   margin-bottom: .5em;
}
.oferta.mini .galeria .precio {
   font-size: 21px;
   top: 145px;
}
.oferta.mini .cta {
   background: #008000;
   border-radius: 3px;
   display: block;
   font-size: 16px;
   line-height: 1;
   margin: .5em;
   min-width: 0;
   width: 100px;
}

/* ===========================================================================
   Portada
   ======================================================================== */
#portada #anuncio {
    background-color: #CCDAFF;
    font-size: 18px;
    padding: .5em 1em;
    position: relative;
    top: -1em;
}
#portada #anuncio .boton {
    background-color: #008000;
    margin-left: .5em;
    text-shadow: none;
}
#portada #anuncio .boton:hover {
    background-color: rgba(0,128,0, 0.8);
}

/* ===========================================================================
   Ciudad
   ======================================================================== */
#ciudad aside #cercanas ul {
    font-size: 16px;
}


/* ===========================================================================
   Expedicio
   ======================================================================== */
#expedicio .descripcio div {
    display: inline-block;
    float: left;
    margin-right: 60px;
}

/* ===========================================================================
   Invoice
   ======================================================================== */
#invoice .descripcio div {
    display: inline-block;
    float: left;
    margin-right: 60px;
}

#invLine_list .amount {
    text-align: right;
}

/* ===========================================================================
   Recerca
   ======================================================================== */
#search form input[type='text'],
#search form input[type='password'],
#search form input[type='email']{
    width: 170px;
}

#search fieldset{
    /*padding: 0 0 1em 0;*/
}

#search legend {
    cursor: pointer;
}
#searchContent {
    display: none;
}

/* ===========================================================================
   Tienda
   ======================================================================== */
#tienda #descripcion {
    line-height: 1.4;
}

#tienda #descripcion h1 {
    margin-bottom: 0;
}

#tienda tr, #tienda th, #tienda td {
    border-width: 1px 0;
}

#tienda table tr *:nth-child(5n+1),
#tienda table tr *:nth-child(5n+2) {
    text-align: left;
}
#tienda table tr *:nth-child(5n+3),
#tienda table tr *:nth-child(5n+4),
#tienda table tr *:nth-child(5n+5) {
    text-align: right;
    white-space: nowrap;
}

/* ===========================================================================
   Formularios
   ======================================================================== */
form label {
    width: 200px;
    vertical-align: middle;
}

form p.help-text {
    margin-left: 200px;
}

/* ===========================================================================
   Página de login + registro
   ======================================================================== */
#login article {
    position: static;
    min-height: 400px;
    overflow: hidden;
    /*padding-top: 2em;*/
    /*width: 100%;*/
}

#login aside {
    display: none;
}

#login #entrar {
    float: left;
    width: 100%;
}
#login #registrar {
    float: right;
    width: 48%;
}

#login #entrar h1 {
    margin-bottom: .5em;
    margin-left: 3em;
}
#login #entrar form {
    margin-left: 6em;
}

#login #entrar input[id='remember_me'] {
    margin-left: 150px;
}

#login #entrar input[type='submit'] {
    font-weight: bold;
    padding: .5em 1em;
    position: static;
    margin-left: 150px;
}

#login #registrar a {
    display: block;
    font-size: 28px;
    margin: 3em 2em;
    text-align: center;
    width: 50%;
}

/* ===========================================================================
   Registre
   ======================================================================== */
#login #registre {
    float: left;    
}

#login #registre form input[type='text'],
#login #registre form input[type='password'],
#login #registre form input[type='email'],
#login #registre form select
{
    width: 170px;
}

#login #registre input[type='submit'] {
    font-weight: bold;
    padding: .5em 1em;
    position: static;
}

#login #registre fieldset{
    padding: 0 0 1em 0;
}


/*
Nav user
*/
/* Targeting both first and second level menus */
.nav li {
	float: left;
	position: relative;
}
.nav li a {
	background: #d6cfbd;
	border: 3px solid #4f4026;
	color: #333;
	display: block;
	margin: 0 5px 0 0;
	padding: 5px 8px;
}
.nav li a:hover {
	background: #f7f7f7;
	text-decoration: none;
}

/* Targeting the first level menu */
.nav {
	display: block;
        display: inline;
	height: 0px;
	padding: 0px 0;
	width: 120px;
	z-index: 100;
	/*position: absolute;*/
        float: right;
        margin: 90px 0 0 0;
}
.nav > li > a {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	-moz-border-radius-topleft: 10px;
	-moz-border-radius-topright: 10px;
	-webkit-border-top-left-radius: 10px;
	-webkit-border-top-right-radius: 10px;
}

/* Targeting the second level menu */
.nav li ul {
	background: #e1ddd3;
	border: 3px solid #4f4026;
	color: #333;
	display: none;
	margin: -3px 0 0 0;
	width: 200px;
	position: absolute;
}
.nav li ul li {
	width: 100%
}
.nav li ul li a {
	background: none;
	border: none;
	line-height: 30px;
	margin: 0;
	padding: 0 0 0 5px;
}
.nav li ul li a:hover {
	background: #f7f7f7;
}

/* Third level menu */
.nav li ul li ul{
	right: -200px; top: 0;
}

/* A class of current will be added via jQuery */
.nav li.current > a {
	background: #f7f7f7;
}
/* CSS fallback */
.nav li:hover > ul.child {
	display: block;
}
.nav li:hover > ul.grandchild  { 
	display: block; 
}