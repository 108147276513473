/*

-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-

  ____  ___  __  __ _   _ _   _ 
 / ___|/ _ \|  \/  | | | | \ | |
| |   | | | | |\/| | | | |  \| |
| |___| |_| | |  | | |_| | |\  |
 \____|\___/|_|  |_|\___/|_| \_|

-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-o-


  Objetivo de esta hoja de estilos
  --------------------------------------------------------------
   Agrupa todos los estilos comunes de todas las partes del sitio
   web, tanto públicas como privadas: tipos de letra, tamaños,
   colores, márgenes, etc.

  Se aplica a
  --------------------------------------------------------------
   frontend, backend y extranet

*/

/* ===========================================================================
   Estilos básicos
   ======================================================================== */
body {
    background: #E6E2DF;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

ul {
    margin-left: 1em;
    padding-left: 1em;
}

ul li, ol li {
    margin-top: .5em;
}

dl dt {
    font-weight: bold;
}
dl dd {
    margin-bottom: 1em;
    margin-left: .5em;
}

h1 { font-size: 21px; margin-top: 0; font-weight: bold }
h2 { font-size: 18px; margin-top: 0; text-decoration: underline; font-weight: bold } /*color: #C06; }*/
h3 { font-size: 16px; margin-top: 0; }
h4 { font-size: 13px; margin-top: 0; }

p {
    margin: 0 0 1em;
}

img {
    outline: 3px solid #E6E2DF;
}

span.si { color: #060; }
span.no { color: #C00; }
h1 span { color: #AAA; }

.oculto { display: none !important; }

/* ===========================================================================
   Tablas
   ======================================================================== */
table {
    border: 1px solid #CCC collapse;
    margin: 1em 0;
    width: 100%;
}
table tr, table th, table td {
    border: 1px solid #CCC;
    padding: .5em .5em;
    text-align: center;
}

/* ===========================================================================
   Formularios
   ======================================================================== */
form > div > div {
    clear: both;
    border-bottom: 1px solid #CCC;
    /*padding: 1em .5em; GD.AP 091119 Cambio el padding para el block dels checkbox de la ficha de usuario.*/
    padding: 0em .5em .5em .5em;
    margin-bottom: .5em; /*GD.AP 091119 Añadido el margin-bottom para el espacio después de la línia. */
}
form label {
    display: inline-block;
    font-weight: bold;
    width: 200px;
    padding-left: .5em;
}
form input, form textarea, form select, form button {
    padding: 5px;
    margin-bottom: .5em;
}
form textarea {
    height: 200px;
    width: 500px;
}
form textarea.mediana {
    height: 100px;
    width: 500px;
}
form label + div[id*="fecha"] {
    display: inline-block;
    overflow: hidden;
}
form label + div[id*="fecha"] > div {
    float: left;
    margin-right: 2em;
}

form p.ayuda {
    color: #555;
    font-size: 14px;
    margin: .5em 0 .5em 200px;
}

form input[type="submit"] {
    /*margin: 1em 0;*/
}
form input.largo {
    width: 500px;
}
form input.corto {
    width: 100px;
}

form > div:first-child > ul:first-child, /* mensajes de error globales */
form div > * > ul:first-child,           /* mensajes de error de cada campo */
form label + ul {
    background: #C00;
    color: #FFF;
    list-style: none;
    margin: 0 0 1em;
    padding: .2em;
}
form > div:first-child > ul:first-child li,
form div > * > ul:first-child li,
form label + ul li {
    margin: 0;
    padding: .2em;
}
form > div:first-child > ul:first-child li + li,
form div > * > ul:first-child li + li,
form label + ul li + li {
    margin-top: .5em;
}

/* ===========================================================================
   Enlaces
   ======================================================================== */
a, a:visited { 
    color: #940000 !important;
    text-decoration: none
}
a:hover { 
    color: #940000 !important; 
    text-decoration: underline
}
a:active  { position: relative; top: 1px; }

/* ===========================================================================
   Formulario de login
   ======================================================================== */
#login article {
    position: relative;
    top: 50px;
    left: 250px;
    min-height: 400px;
}
#login article h1 {
    font-size: 28px;
}

#login form label {
    font-size: 18px;
    font-weight: normal;
    width: 150px;
}

#login form input {
    font-size: 18px;
}
#login form input[type="submit"] {
    position: relative;
    left: 310px;
}

/* ===========================================================================
   Botones
   ======================================================================== */
/* Botón normal */

.boton, .boton:visited {
    background: #aba8b1;
    border-bottom: 1px solid rgba(0,0,0, 0.25);
    color: #940000;
    cursor: pointer;
    display: inline-block;
    font: 24px georgia, "Times New Roman", Times, serif;
    line-height: 1;
    padding: .25em .5em;
    text-decoration: none;
    /*
    background: rgb(204,0,102);
    border-bottom: 1px solid rgba(0,0,0, 0.25);
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    padding: .5em 1em;
    text-decoration: none;
    */
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: .2em;

    -moz-box-shadow: 0 1px 3px rgba(0,0,0, 0.6);
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0, 0.6);
    box-shadow: 0 1px 3px rgba(0,0,0, 0.6);

    text-shadow: 0 1px 1px #603;
    margin-top: .5em;
}
.boton:hover {
    /*background: rgba(205,0,102, 0.8);
    color: #FFF !important;*/
}

/* Botón click-to-action */
.cta {
    background: #0035C7;
    border-radius: 5px;
    color: #FFF !important;
    display: inline-block;
    font-size: 21px;
    line-height: 30px;
    padding: 12px 15px 12px 10px;
    margin: 1em;
    min-width: 50%;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.cta strong {
    background: #FFF;
    border-radius: 5px;
    color: #0035C7;
    font-size: 24px;
    font-weight: normal;
    margin-right: 10px;
    padding: 5px 10px;
    -moz-box-shadow: 0px 0px 5px #4275FF;
    -webkit-box-shadow: 0px 0px 5px #4275FF;
    box-shadow: 0px 0px 5px #4275FF;
}

/* ===========================================================================
   Estructura
   ======================================================================== */
#container {
    background: #FFF;
    margin: 0 auto 1em;
    /*width: 960px;*/
    width: 90%;

    -moz-box-shadow: 0 1px 5px #444;
    -webkit-box-shadow: 0 1px 5px #444;
    box-shadow: 0 1px 5px #444;
}

#container > article {
    min-height: 400px;
}

footer {
    clear: both;
}

/* ===========================================================================
   Cabecera y menú de navegación
   ======================================================================== */
/*header {
    background: #00154E;
    color: #FFF;
    border-bottom: 1px solid #CCC;
    height: 60px;
    margin-bottom: 1em;
    overflow: hidden;
    position: relative;
}*/

header {
  width: 100%;
  /*height: 170px;*/
  background: url(/images/site/header-bg.jpg) repeat;
  margin: 0 auto;
}

header a, header a:visited {
    /*border-bottom: 1px solid;*/
    text-decoration: none;
}
header a:hover {
    color: #FF0A85 !important;
}

/*
header h1 {
    position: absolute;
    left: 20px;
    line-height: 60px;
    margin: 0;
}
*/
header h1 {
  background: url(/images/logo.png) no-repeat;
  display: inline;
  float: left;
  height: 124px;
  overflow: hidden;
  text-indent: -9999px;
  width: 589px;
  margin: 10px 0 0 -50px;
}

header h1 a {
    border: none;
    color: #FFF !important;
    font-size: 28px;
    text-decoration: none;
}

header nav {
  width: 100%;
  height: 60px;
  display: inline;
  float: right;
  margin-right: 0px;
}

/*
nav ul {
    list-style: none;
    position: absolute;
    left: 200px;
    right: 20px;
}*/

nav ul li {
    float: left;
    /*line-height: 60px;*/
    margin: 0;
    padding-left: .5em;
}
/*
nav ul li a {
    color: #FFF !important;
    font-size: 18px;
}
*/

nav ul li a, 
nav ul li a:hover, nav ul li a:visited, nav ul li a:active {
  /*background: url(../img/btMenu01.gif) no-repeat 0 0;*/
  background: #aba8b1;
  border: 2px solid #7e7d7f;
  /*width: 109px;*/
  height: 28px;
  display: inline;
  overflow: hidden;
  float: left;
  /*margin: 0 2px 0 5px;*/
  font: 24px georgia, "Times New Roman", Times, serif;
  text-decoration: none;
  color: #940000 !important;
  text-shadow: 1px 1px 2px #666;
  /*padding: 2px 0 0 0px;    */
  padding: .25em .5em;
  text-align: center;
  box-sizing: content-box;
}


nav ul {
  float: left;
  display: inline;
  width: 100%;
  /*margin-top: 47px;*/
}

nav ul li form {
    margin-right: 10px;
}

nav ul li form label {
    display: block;
}

/* ===========================================================================
   Zona lateral
   ======================================================================== */
aside {
    font-size: 13px;
}

aside h2 {
    font-size: 16px;
    margin-bottom: .25em;
}

aside a {
    text-decoration: none;
}
aside a:hover {
    text-decoration: underline;
}

aside ul {
    margin-top: .25em;
}

aside section {
    margin-bottom: 1em;
}

/* ===========================================================================
   Pie de página
   ======================================================================== */
footer {
    color: #999;
    border-top: 1px solid #CCC;
    font-size: 13px;
    padding: 1em;
    position: relative;
}

footer a {
    color: #999;
    padding: 0 1em;
}
footer:hover a {
    color: #11C;
}

footer #idioma {
    position: absolute;
    right: 1em;
    top: 1em;
}
/* ===========================================================================
   Mensajes flash
   ======================================================================== */
p#flash {
    background: #DCDCDC;
    margin-bottom: 1em;
    padding: .5em 1em;
}

p#flash.info {
    background: #090;
    color: #FFF;
}
p#flash.error {
    background: #C00;
    color: #FFF;
}

/* ===========================================================================
   Paginador
   ======================================================================== */
ul.paginador {
    background: #DCDCDC;
    list-style: none;
    margin: 0 0 1em 0;
    padding: .5em;
    text-align: center;
}

ul.paginador li {
    display: inline;
    margin: 0;
    padding: 0 1em;
}
ul.paginador li.actual {
    font-weight: bold;
}
