/* 
    Document   : userCombobox
    Created on : 05-abr-2013, 13:11:14
    Author     : apujadas
    Description:
        Estil del combobox autocomplete.
*/

.ui-combobox {
    position: relative;
    display: inline-block;
}
.ui-combobox-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    padding: 0;
    /* support: IE7 */
    *height: 1.7em;
    *top: 0.1em;
}
.ui-combobox-input {
    margin-bottom: 0.5em;
    padding: 0.3em;
}
.ui-autocomplete {
    max-height: 300px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}
/* IE 6 doesn't support max-height
* we use height instead, but this forces the menu to always be this tall
*/
* html .ui-autocomplete {
    height: 300px;
}