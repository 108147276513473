
.button {
        display: inline-block;
        margin-right: .5em;
        box-shadow: inset 0px 1px 0px rgba(255,255,255,0.4),
                                        0px 0px 25px rgba(0,0,0,0.3);

        padding: 20px 20px;
        border: 1px solid #562f15;
        font-size: 20px;
        font-weight: normal;
        color: #fff;
        text-shadow: 1px 1px 1px #7f130a;
        border-radius: 3px;
        cursor: pointer;
        background: rgb(147,80,36);
        background: -moz-linear-gradient(top,  rgba(147,80,36,1) 19%, rgba(106,58,26,1) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(19%,rgba(147,80,36,1)), color-stop(100%,rgba(106,58,26,1)));
        background: -webkit-linear-gradient(top,  rgba(147,80,36,1) 19%,rgba(106,58,26,1) 100%);
        background: -o-linear-gradient(top,  rgba(147,80,36,1) 19%,rgba(106,58,26,1) 100%);
        background: -ms-linear-gradient(top,  rgba(147,80,36,1) 19%,rgba(106,58,26,1) 100%);
        background: linear-gradient(to bottom,  rgba(147,80,36,1) 19%,rgba(106,58,26,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#935024', endColorstr='#6a3a1a',GradientType=0 );
}
	
.button:hover {
        background: rgb(167,91,41);
        background: -moz-linear-gradient(top,  rgba(167,91,41,1) 19%, rgba(126,69,31,1) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(19%,rgba(167,91,41,1)), color-stop(100%,rgba(126,69,31,1)));
        background: -webkit-linear-gradient(top,  rgba(167,91,41,1) 19%,rgba(126,69,31,1) 100%);
        background: -o-linear-gradient(top,  rgba(167,91,41,1) 19%,rgba(126,69,31,1) 100%);
        background: -ms-linear-gradient(top,  rgba(167,91,41,1) 19%,rgba(126,69,31,1) 100%);
        background: linear-gradient(to bottom,  rgba(167,91,41,1) 19%,rgba(126,69,31,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a75b29', endColorstr='#7e451f',GradientType=0 );
}

.button:active {
        box-shadow: 0px 0px 25px rgba(0,0,0,0.3),
                                        inset 0px 0px 20px #412410;
}
	
.overlay-container {
        display: none;
        content: " ";
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: -moz-radial-gradient(center, ellipse cover,  rgba(127,127,127,0) 0%, rgba(127,127,127,0.9) 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(127,127,127,0)), color-stop(100%,rgba(127,127,127,0.9)));
        background: -webkit-radial-gradient(center, ellipse cover,  rgba(127,127,127,0) 0%,rgba(127,127,127,0.9) 100%);
        background: -o-radial-gradient(center, ellipse cover,  rgba(127,127,127,0) 0%,rgba(127,127,127,0.9) 100%);
        background: -ms-radial-gradient(center, ellipse cover,  rgba(127,127,127,0) 0%,rgba(127,127,127,0.9) 100%);
        background: radial-gradient(center, ellipse cover,  rgba(127,127,127,0) 0%,rgba(127,127,127,0.9) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007f7f7f', endColorstr='#e67f7f7f',GradientType=1 );
}

.window-container {
        display: block;
        background: #fcfcfc;
        margin: 8em auto;
        width: 700px;
        padding: 10px 20px 20px;
        text-align: left;
        z-index: 30;
        border-radius: 3px;
        box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
        -ms-transition: 0.4s ease-out;
        -o-transition: 0.4s ease-out;
        transition: 0.4s ease-out;
        opacity: 0;
}

.zoomin {
        -webkit-transform:  scale(1.2);
        -moz-transform:  scale(1.2);
        -ms-transform:  scale(1.2);
        transform:  scale(1.2);
}

.zoomout {
        -webkit-transform:  scale(0.7);
        -moz-transform:  scale(0.7);
        -ms-transform:  scale(0.7);
        transform:  scale(0.7);
}

.window-container-visible {
        -webkit-transform:  scale(1);
        -moz-transform:  scale(1);
        -ms-transform:  scale(1);
        transform:  scale(1);
        opacity: 1;
        position: relative;
}
	
.window-container h1 {
        margin: 1em 0 0.5em;
        text-align: center;
        color: #940000;
}

.close {
        margin: 1em auto;
        display: block;
        float:  none;
        width: 75px;
        background: #fafafa;
        background: -moz-linear-gradient(top,  #fafafa 0%, #f4f4f4 40%, #e5e5e5 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fafafa), color-stop(40%,#f4f4f4), color-stop(100%,#e5e5e5));
        background: -webkit-linear-gradient(top,  #fafafa 0%,#f4f4f4 40%,#e5e5e5 100%);
        background: -o-linear-gradient(top,  #fafafa 0%,#f4f4f4 40%,#e5e5e5 100%); 
        background: -ms-linear-gradient(top,  #fafafa 0%,#f4f4f4 40%,#e5e5e5 100%);
        background: linear-gradient(to bottom,  #fafafa 0%,#f4f4f4 40%,#e5e5e5 100%); 
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#e5e5e5',GradientType=0 );
        border: 1px solid #aaa;
        padding: 5px 14px;
        color: #444;
        font-family: Helvetica, sans-serif;
        font-size: 12px;
        border-radius: 3px;
        box-shadow: 0 1px 3px #ddd;
        -webkit-transition: 0.2s linear;
        -moz-transition: 0.2s linear;
        -ms-transition: 0.2s linear;
        -o-transition: 0.2s linear;
        transition: 0.2s linear;
        cursor: pointer;
        text-align: center;
        opacity: 1.0;
}
	
.close:hover {
        background: #fefefe;
        background: -moz-linear-gradient(top,  #fefefe 0%, #f8f8f8 40%, #e9e9e9 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fefefe), color-stop(40%,#f8f8f8), color-stop(100%,#e9e9e9));
        background: -webkit-linear-gradient(top,  #fefefe 0%,#f8f8f8 40%,#e9e9e9 100%);
        background: -o-linear-gradient(top,  #fefefe 0%,#f8f8f8 40%,#e9e9e9 100%);
        background: -ms-linear-gradient(top,  #fefefe 0%,#f8f8f8 40%,#e9e9e9 100%);
        background: linear-gradient(to bottom,  #fefefe 0%,#f8f8f8 40%,#e9e9e9 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e9e9e9',GradientType=0 );
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        border: 1px solid #aaa;
}

.close:active {
        background: #f4f4f4;
        background: -moz-linear-gradient(top,  #f4f4f4 0%, #efefef 40%, #dcdcdc 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f4f4f4), color-stop(40%,#efefef), color-stop(100%,#dcdcdc));
        background: -webkit-linear-gradient(top,  #f4f4f4 0%,#efefef 40%,#dcdcdc 100%);
        background: -o-linear-gradient(top,  #f4f4f4 0%,#efefef 40%,#dcdcdc 100%);
        background: -ms-linear-gradient(top,  #f4f4f4 0%,#efefef 40%,#dcdcdc 100%);
        background: linear-gradient(to bottom,  #f4f4f4 0%,#efefef 40%,#dcdcdc 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#dcdcdc',GradientType=0 );
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}
