/*

  Objetivo de esta hoja de estilos
  --------------------------------------------------------------
   Normaliza el estilo de todos los elementos HTML para que por
   defecto se vean bien e igual en todos los navegadores.

  Se aplica a
  --------------------------------------------------------------
   frontend, backend y extranet
  
  Copiado de
  --------------------------------------------------------------
   https://github.com/necolas/normalize.css

*/


/* ===========================================================================
   HTML5 display definitions
   ======================================================================== */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }
audio:not([controls]) { display: none; }
[hidden] { display: none; }

/* ===========================================================================
   Base
   ======================================================================== */

/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Force vertical scrollbar in non-IE
 * 3. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 */

html { font-size: 100%; overflow-y: scroll; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }

body { margin: 0; font-size: 13px; line-height: 1.231; }

body, button, input, select, textarea { font-family: sans-serif; color: #222; }

/* 
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */

/*::-moz-selection { /*background: #fe57a1; color: #fff; text-shadow: none; }*/
/*::selection { /*background: #fe57a1; color: #fff; text-shadow: none; }*/


/* ===========================================================================
   Links
   ======================================================================== */

a { color: #00e; }
a:visited { color: #551a8b; }
a:hover { color: #06e; }
a:focus { outline: thin dotted; }

/* Improve readability when focused and hovered in all browsers: h5bp.com/h */
a:hover, a:active { outline: 0; }


/* ===========================================================================
   Typography
   ======================================================================== */

abbr[title] { border-bottom: 1px dotted; }

b, strong { font-weight: bold; }

blockquote { margin: 1em 40px; }

dfn { font-style: italic; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

ins { background: #ff9; color: #000; text-decoration: none; }

mark { background: #ff0; color: #000; font-style: italic; font-weight: bold; }

/* Redeclare monospace font family: h5bp.com/j */
pre, code, kbd, samp { font-family: monospace, monospace; _font-family: 'courier new', monospace; font-size: 1em; }

/* Improve readability of pre-formatted text in all browsers */
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }

q { quotes: none; }
q:before, q:after { content: ""; content: none; }

small { font-size: 85%; }

/* Position subscript and superscript content without affecting line-height: h5bp.com/k */
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }


/* ===========================================================================
   Lists
   ======================================================================== */

ul, ol { margin: 1em 0; padding: 0 0 0 40px; }
dd { margin: 0 0 0 40px; }
nav ul, nav ol { list-style: none; list-style-image: none; margin: 0; padding: 0; }


/* ===========================================================================
   Embedded content
   ======================================================================== */

/*
 * 1. Improve image quality when scaled in IE7: h5bp.com/d
 * 2. Remove the gap between images and borders on image containers: h5bp.com/e 
 */

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }

/*
 * Correct overflow not hidden in IE9 
 */

svg:not(:root) { overflow: hidden; }


/* ===========================================================================
   Figures
   ======================================================================== */

figure { margin: 0; }


/* ===========================================================================
   Forms
   ======================================================================== */

form { margin: 0; }
fieldset { border: 0; margin: 0; padding: 0; }

/* Indicate that 'label' will shift focus to the associated form element */
label { cursor: pointer; }

/* 
 * 1. Correct color not inheriting in IE6/7/8/9 
 * 2. Correct alignment displayed oddly in IE6/7 
 */

legend { border: 0; *margin-left: -7px; padding: 0; }

/*
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */

button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }

/*
 * 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
 * 2. Correct inner spacing displayed oddly in IE6/7
 */

button, input { line-height: normal; *overflow: visible; }

/*
 * Reintroduce inner spacing in 'table' to avoid overlap and whitespace issues in IE6/7
 */

table button, table input { *overflow: auto; }

/*
 * 1. Display hand cursor for clickable form elements
 * 2. Allow styling of clickable form elements in iOS
 */

button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button; }

/*
 * Consistent box sizing and appearance
 */

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; }
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

/* 
 * Remove inner padding and border in FF3/4: h5bp.com/l 
 */

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

/* 
 * 1. Remove default vertical scrollbar in IE6/7/8/9 
 * 2. Allow only vertical resizing
 */

textarea { overflow: auto; vertical-align: top; resize: vertical; }

/* Colors for form validity */
input:valid, textarea:valid {  }
input:invalid, textarea:invalid { background-color: #f0dddd; }


/* ===========================================================================
   Tables
   ======================================================================== */

table { border-collapse: collapse; border-spacing: 0; }
td { vertical-align: top; }